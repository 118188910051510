import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js'
import background from '../images/background.jpg'

function create3DBackground() {
    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(window.innerWidth / -2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / -2, 1, 10000);
    const renderer = new THREE.WebGLRenderer();
    const composer = new EffectComposer(renderer);
    const renderPass = new RenderPass(scene, camera);
    const filmPass = new FilmPass(1, 0.3, 2048, 0);

    composer.addPass(renderPass);
    composer.addPass(filmPass);

    renderer.setSize(window.innerWidth, window.innerHeight);

    document.body.appendChild(renderer.domElement);

    const textureLoader = new THREE.TextureLoader();
    const geometry = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight);
    const texture = textureLoader.load(background);
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const plane = new THREE.Mesh(geometry, material);

    plane.position.z = -10;

    scene.add(plane);

    function animate() {
        requestAnimationFrame(animate);
        composer.render();
    }


    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);
    }

    window.addEventListener( 'resize', onWindowResize, false );

    animate();
}

export default create3DBackground;
